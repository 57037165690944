.ql-size-10 {
  font-size: 10px !important;
}
.ql-size-12 {
  font-size: 12px !important;
}
.ql-size-14 {
  font-size: 14px !important;
}
.ql-size-16 {
  font-size: 16px !important;
}
.ql-size-18 {
  font-size: 18px !important;
}
.ql-size-20 {
  font-size: 20px !important;
}
.ql-size-24 {
  font-size: 24px !important;
}
.ql-size-32 {
  font-size: 32px !important;
}
.ql-size-48 {
  font-size: 48px !important;
}
.ql-size-64 {
  font-size: 64px !important;
}
